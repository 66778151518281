import moment from 'moment';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'datepicker',
    emits: ['dateChange'],
    props: {
        date: {
            // required: true,
            type: String,
            default: ''
        },
        yearsInFuture: Number,
    },
    watch: {
        date(newValue) {
            this.setValues(newValue);
        }
    },
    mounted() {
        if (this.date) {
            this.setValues(this.date);
        }
    },
    data: () => {
        return {
            birthDate: '',
            birthDay: '',
            birthMonth: '',
            birthYear: '',
            days: new Date((new Date()).getFullYear(), 1, 0).getDate(),
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        };
    },
    computed: {
        years() {
            if (this.yearsInFuture && this.yearsInFuture > 0) {
                return [...Array(this.yearsInFuture).keys()].map((y) => ((new Date()).getFullYear() + y));
            }
            return [...Array(81).keys()].map((i) => ((new Date()).getFullYear()) - i);
        },
    },
    methods: {
        setDate() {
            const birthDateArray = [];
            if (this.birthYear) {
                birthDateArray.push(this.birthYear);
            }
            if (typeof this.birthMonth !== 'undefined') {
                let { birthMonth } = this;
                birthMonth = parseFloat(birthMonth) < 10 ? `0${birthMonth}` : birthMonth;
                birthDateArray.push(birthMonth);
            }
            if (this.birthDay) {
                const birthDay = parseFloat(this.birthDay) < 10 ? `0${this.birthDay}` : this.birthDay;
                birthDateArray.push(birthDay);
            }
            this.birthDate = birthDateArray.join('-');
            this.$emit('dateChange', this.birthDate);
        },
        setDays() {
            const daysInMonth = new Date(+this.birthYear, (+this.birthMonth + 1), 0).getDate();
            this.days = daysInMonth;
        },
        setValues(newValue) {
            if (!newValue) {
                return;
            }
            const birthDateMoment = moment(newValue, 'YYYY-MM-DD', true);
            if (newValue && birthDateMoment.isValid()) {
                const dateArray = newValue.split('-');
                const birthYear = dateArray[0];
                this.birthYear = birthYear;
                const birthMonth = dateArray[1];
                this.birthMonth = window.parseInt(birthMonth).toString();
                const birthDay = dateArray[2];
                this.birthDay = birthDay;
            }
        }
    }
});
