import { defineComponent } from 'vue';
export default defineComponent({
    name: 'insurance-type-selector-component',
    props: {
        insurances: {
            type: Array,
            required: true,
        },
        label: String,
    },
    computed: {
        caption() {
            return this.label || 'Insurance Type';
        }
    },
    data() {
        return {
            selectedInsurance: null,
        };
    },
    methods: {
        onChangeInsurance() {
            const insurance = this.insurances.find((insr) => insr.id === this.selectedInsurance);
            this.$emit('on-select', insurance);
        }
    }
});
