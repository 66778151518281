import { defineComponent } from 'vue';
import ChipState from '@/core/components/ui/ChipStateDepracated.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
export default defineComponent({
    name: 'insurance-status-component',
    components: {
        ChipState
    },
    props: {
        verificationState: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            UserVerificationStatusEnum
        };
    },
});
