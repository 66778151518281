import moment from 'moment';
// eslint-disable-next-line no-unused-vars
export const validateInsuranceExpirationDateValidator = (rule, value, cb) => {
    const date = moment(value, 'YYYY-MM-DD', true);
    if (date.isValid()) {
        const givenDate = date.toDate();
        const currentDate = new Date();
        if (givenDate >= currentDate) {
            cb();
        }
        else {
            cb(new Error('Not allowed to select date less than the current date'));
        }
    }
    else {
        cb(new Error('Invalid Date'));
    }
};
