import { defineComponent } from 'vue';
import Datepicker from '@/core/components/ui/Datepicker.vue';
import FileUploadComponent from '@/modules/account-details/components/FileUploadComponent.vue';
import { validateInsuranceExpirationDateValidator } from '@/modules/account-details/validators';
export default defineComponent({
    name: 'ProfessionalIndemnity',
    components: {
        FileUploadComponent,
        Datepicker
    },
    props: ['insurance', 'disabled', 'submitting'],
    data() {
        return {
            proofRequired: true,
            attachment: ''
        };
    },
    created() {
        const { attachment } = this.$props.insurance;
        const proof = attachment ? attachment[0] : '';
        if (proof && proof.attachment) {
            this.proofRequired = false;
            this.attachment = proof.attachment;
        }
    },
    computed: {
        currentInsurance() {
            return this.insurance;
        },
        rules() {
            return {
                name: [
                    {
                        required: true,
                        message: 'Insurance name is required',
                        trigger: 'change'
                    }
                ],
                policyNumber: [
                    {
                        required: true,
                        message: 'Policy number is required',
                        trigger: 'change'
                    }
                ],
                expiryDate: [
                    {
                        required: true,
                        validator: validateInsuranceExpirationDateValidator,
                        trigger: 'change'
                    },
                ],
                sum: [
                    {
                        required: true,
                        message: 'Sum of insurance is required',
                        trigger: 'change'
                    }
                ],
                proof: [
                    {
                        required: this.proofRequired,
                        message: 'Proof of certificate is required',
                        trigger: 'change'
                    }
                ]
            };
        }
    },
    methods: {
        onSubmit() {
            this.$refs.publicLiabilityForm
                .validate((valid) => {
                if (valid) {
                    this.$emit('on-submit', this.insurance);
                }
            });
        },
        onDateChange(date) {
            this.currentInsurance.metadata.expiryDate = date;
            const metadata = { ...this.insurance.metadata };
            this.currentInsurance.metadata = metadata;
        },
        updateProof(value) {
            if (!value) {
                return;
            }
            const form = this.$el;
            const proofField = form.querySelector('.upload-container');
            this.currentInsurance.attachment = value;
            this.proofRequired = false;
            setTimeout(() => {
                proofField.classList.add('is-required');
            }, 10);
        }
    }
});
